export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12')
];

export const server_loads = [];

export const dictionary = {
		"/": [2],
		"/lending-markets": [4],
		"/portfolio": [5],
		"/stats": [6],
		"/stats/[chainId=integer]": [7],
		"/swap": [8],
		"/tokens": [10],
		"/token/[chainId=integer]/[tokenAddress=address]": [9],
		"/yield": [11],
		"/yield/[chainId=integer]/[farmAddress]/[poolId=integer]": [12],
		"/[bundle=bundle]": [3]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';