import { isAddressOrENSValid } from '$lib/common/helpers/isAddressValid';
import type { ParamMatcher } from '@sveltejs/kit';

export const match: ParamMatcher = param => {
  const substrings = param.split('+');
  for (let i = 0; i < substrings.length; i++) {
    const p = substrings[i];
    if (!isAddressOrENSValid(p)) return false;
  }
  return true;
};
